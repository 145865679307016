
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.WebContainer{
  width: 100%;
  height: auto;
}
.articles{
  width:90%; 
  height: auto;
  margin: auto;
} 



body {
  background-image: url('https://www.toptal.com/designers/subtlepatterns/uploads/beige-tiles.png'); 
  height: 100vh;
  
}

.HomeText{
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: left;
} 

.HomeText h1 {
  margin-bottom:30px;
}

.HomeText h4 {
  margin-bottom:30px;
}

.HomeContainer{
  display:flex;
  align-items:flex-end;  
  justify-content: center;
  align-items: bottom;
  padding-top: 8%;

}

.NavBarContainer {
  display:flex;
  justify-content: space-between;


}

.navBarLinks {
  display: flex;
  font-size: 15px;
  justify-content: flex-end;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.NavBarTitle {
  font-size: 25px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.navBarLinks a {
  margin-right: 45px;
  font-size: 20px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

a{
  color: black;
  text-decoration: none;
  display: block;
  font-size: 20px;
 
}
a:hover{
  text-decoration: underline;
}

.box{
  background-color:white;
  border-radius:10px 10px 10px 10px;
  overflow: hidden;
  max-height: 200px;
  -webkit-box-shadow: 0px 12px 18px -6px rgba(0,0,0,0.3);
  box-shadow:0px 12px 18px -6px rgba(0,0,0,0.3) ;
  margin:20px;
}

.grid{
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    margin-right: 30px;
    margin-left: 30px;
}


.AboutMeTitle{
  text-align: left;
  font-size:50pt;
  margin-left: 50px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.AboutMeParagraph{
  text-align: left;
  font-size:20pt;
  margin-left: 50px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}



.formbox {
  width:700px;
  height:1700px;
  padding:20px;
  display: inline-block;
}

.FormBoxTitle{
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

input[type=text]{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  width:100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea[type=text]{
  width: 100%;
  height: auto;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

button {
  appearance:none;
  -webkit-appearance:none;
  padding:10px;
  border:none;
  background-color:#bdd7d8;
  color:black;
  font-weight:600;
  border-radius:5px;
  width:100%;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.ContactMeLinks {
  text-align: center;
  display:inline-block;
  font-family: Georgia, 'Times New Roman', Times, serif;
}


.circleCourtney img{
  width: 100%;
  height: auto;
}

@media  only screen and (max-width:700px){
  .HomeContainer {flex-wrap: wrap-reverse;}
}